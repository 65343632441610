body {
  background: url("./assets/bg-polygon.jpg") repeat 0 0 #313133;
}

.logoImg {
  width: 18vw;
}

.card {
  margin: auto;
  width: 90%;
  height: 80%;
}

.cardContent {
  width: 100%;
  height: 100%;
  min-height: 512px;
  box-shadow: 0px 10px 70px 0px rgba(255, 255, 255, 0.55), 0px 30px 40px 0px rgba(255, 255, 255, 0.35);
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 10%, rgba(255, 255, 255, 0) 100%), url("./assets/bg-space.jpg") no-repeat 50% 90%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  animation: slide 1s forwards;
  animation-delay: 0.3s;
  margin-top: 0px;
  opacity: 0;
}

@keyframes slide {
  100% {
    margin-top: 10px;
    opacity: 1;
  }
}

.border-left__orange:before {
  content: '';
  position: absolute;
  left: 0;
  top: -2px;
  bottom: -2px;
  width: 5px;
  background: #fd4f00;
  z-index: 10;
}

@media screen and (max-width: 512px) {
  .card {
    width: 100%;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
  }
}

.header {
  display: flex;
  padding: 48px 0 0 48px;
  justify-content: space-between;
  align-items: center;
  color: #000;
}

.logo {
  font-weight: bold;
  font-size: 1.5em;
  transition: opacity 0.05s ease;
}

.logo:hover {
  opacity: 0.75;
}

.social {
  display: flex;
}

.social a {
  display: inline-block;
  margin-right: 12px;
  transition: opacity 0.05s ease;
}

.social a:last-child {
  margin-right: 0;
}

.social a:hover {
  opacity: 0.75;
}

.social .icon {
  width: 18px;
  fill: #fff;
}

.content {
  flex: 1 1 auto;
  min-height: 256px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content .title-holder {
  color: #fff;
  text-align: center;
  margin-bottom: 24px;
  position: relative;
}

.content .title-holder h1 {
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 12px;
  position: relative;
}

.content .title-holder p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  padding-left: 10px;
  color: rgba(255, 255, 255, 0.75);
  position: relative;
}

@media screen and (max-width: 768px) {
  .content .title-holder {
    max-width: 80%;
  }
  .logoImg {
    width: 32vw;
  }
}

.content .cta {
  min-width: 64px;
  padding: 16px 24px;
  background: #fff;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  transform: none;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.25, 0.315, 1.35), transform 0.1s linear;
}

.content .cta:hover {
  transform: translateY(-1px);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.3);
}

a.underlined:hover {
  border-bottom: 1px dotted #fff;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}